import { axiosInstance } from "./general";
import { v4 as uuidv4 } from "uuid";
import qs from "qs";

// Function to generate a new Conversation ID
// const getNewConversationId = () => uuidv4();
const conversationId = uuidv4();
console.log("-----CONVERSATION ID: ", conversationId);

// Initialize conversationId at runtime
// let conversationId = getNewConversationId();

// Function to reset Conversation ID
// export const resetConversationId = () => {
//   conversationId = getNewConversationId();
//   console.log("Conversation ID Reset:", conversationId);
// };

// Call resetConversationId on module load to ensure it's fresh
// resetConversationId();

export const initializeChat = async () => {
  try {
    const response = await axiosInstance.get(`/chat/initialize-chat`, {
      headers: {
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching initial message:", error);
    throw error;
  }
};

export const sendMessageToChatbot = async (formData) => {
  try {
    const response = await axiosInstance.post(`/chat/chat`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });
    console.log("RESPONSE DATA: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const updateFormConfig = async (data) => {
  try {
    const response = await axiosInstance.post(`/update-form`, data, {
      headers: {
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating form config:", error);
    throw error;
  }
};

export const processFiles = async (formData) => {
  try {
    const response = await axiosInstance.post(`/process-files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Conversation-ID": conversationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error processing files:", error);
    throw error;
  }
};

export const submitForm = async (formConfig, filesNames) => {
  try {
    const response = await axiosInstance.post(
      `/requests/submit-form`,
      {
        intake: {
          form: formConfig,
          files_names: filesNames,
        },
      },
      {
        headers: {
          "Conversation-ID": conversationId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};

export const updateRequest = async (requestId, formData) => {
  try {
    const response = await axiosInstance.post(
      `/requests/update-request`,
      {
        request_id: requestId,
        form: formData,
      },
      {
        headers: { "Conversation-ID": conversationId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating request:", error);
    throw error;
  }
};

export const cancelRequest = async (requestId) => {
  try {
    const response = await axiosInstance.post(
      `/requests/cancel-request`,
      {
        request_id: requestId,
      },
      {
        headers: { "Conversation-ID": conversationId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error cancelling request:", error);
    throw error;
  }
};

/**
 * Search fields based on field_id and search query.
 * @param {string} fieldId - The ID of the field to search.
 * @param {string} searchQuery - The search string input by the user.
 * @returns {Promise<Object>} - The search results from the backend.
 */
export const searchField = async (fieldId, searchQuery) => {
  try {
    const response = await axiosInstance.get(`/misc/search-field`, {
      params: {
        field_id: fieldId,
        search: searchQuery,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    });
    console.log(response.data);
    return response.data.results;
  } catch (error) {
    console.error("Error searching field:", error);
    throw error;
  }
};
