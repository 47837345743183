import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import EditableTable from "./EditableTable";
import { useTheme } from "@mui/material/styles";
import { getVisibleSections, formatCurrency } from "../../utils/formUtils";
import { searchField } from "../../api/chat";
import debounce from "lodash.debounce";

const InputField = ({
  field,
  sectionIndex,
  fieldIndex,
  handleChange,
  handleTableRowsChange,
  chatbotLoading,
  conversationOver,
  disabled,
  hasError,
  inverseBackground = false,
  isSmall = false,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // Track the latest search term
  const latestSearchTermRef = useRef("");

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce(async (value) => {
        latestSearchTermRef.current = value;
        if (value.length >= 3) {
          try {
            const results = await searchField(field.id, value);
            if (latestSearchTermRef.current === value) {
              setOptions([...new Set(results.map((item) => item))]);
            }
          } catch (error) {
            console.error("Debounced search error:", error);
            setOptions([]);
          }
        } else {
          setOptions([]);
        }
      }, 500),
    [field.id]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Handler for currency formatting
  const handleCurrencyChange = (e) => {
    let { value } = e.target;
    // Remove all non-digit characters except for the decimal point
    value = value.replace(/[^\d.]/g, "");

    // Update the parent with the unformatted value
    handleChange({ target: { value } }, sectionIndex, fieldIndex);

    // Optionally, you can format the displayed value here or let it be handled in the rendering
  };

  switch (field.type) {
    case "table":
      return (
        <EditableTable
          rows={field.value}
          columns={field.columns}
          onRowsChange={(updatedRows) =>
            handleTableRowsChange(sectionIndex, fieldIndex, updatedRows)
          }
          disabled={chatbotLoading || conversationOver || disabled}
        />
      );

    case "select":
      return (
        <FormControl
          fullWidth
          error={hasError}
          size={isSmall ? "small" : "medium"}
        >
          <InputLabel>{field.name}</InputLabel>
          <Select
            label={field.name + (field.required ? "*" : "")}
            name={String(fieldIndex)}
            value={field.value?.value || ""}
            onChange={(e) => {
              const selectedOption = field.options.find(
                (option) => option.value === e.target.value
              );
              handleChange(
                { target: { value: selectedOption } },
                sectionIndex,
                fieldIndex
              );
            }}
            displayEmpty
            disabled={chatbotLoading || conversationOver || disabled}
            sx={{
              backgroundColor: inverseBackground
                ? "background.dark"
                : "background.default",
              borderRadius: "12px",
            }}
          >
            {field.options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case "textarea":
      return (
        <TextField
          fullWidth
          label={field.name + (field.required ? "*" : "")}
          name={String(fieldIndex)}
          type="text"
          value={field.value || ""}
          size={isSmall ? "small" : "medium"}
          onChange={(e) => handleChange(e, sectionIndex, fieldIndex)}
          multiline
          rows={4}
          InputLabelProps={{
            shrink: Boolean(field.value),
          }}
          disabled={chatbotLoading || conversationOver || disabled}
          error={hasError}
          helperText={hasError ? "" : ""}
          sx={{
            backgroundColor: inverseBackground
              ? "background.dark"
              : "background.default",
            borderRadius: "12px",
          }}
        />
      );

    case "text":
    case "number":
    case "date":
      return (
        <>
          <TextField
            fullWidth
            label={field.name + (field.required ? "*" : "")}
            name={String(fieldIndex)}
            type={field.format === "currency" ? "text" : field.type}
            size={isSmall ? "small" : "medium"}
            value={
              field.format === "currency"
                ? formatCurrency(field.value)
                : field.value || ""
            }
            onChange={
              field.format === "currency"
                ? handleCurrencyChange
                : (e) => handleChange(e, sectionIndex, fieldIndex)
            }
            InputLabelProps={{
              shrink:
                field.type === "date" ||
                field.type === "datetime-local" ||
                Boolean(field.value),
            }}
            disabled={chatbotLoading || conversationOver || disabled}
            sx={{
              backgroundColor: inverseBackground
                ? "background.dark"
                : "background.default",
              borderRadius: "12px",
            }}
            error={hasError}
            helperText={hasError ? "" : ""}
          />
          <Typography
            variant="subtitle"
            sx={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: field.helper_text }}
          />
        </>
      );

    case "autocomplete":
      return (
        <Autocomplete
          freeSolo={field?.free_solo === "true"}
          options={field.options}
          value={field.value}
          disabled={chatbotLoading || conversationOver || disabled}
          onInputChange={(event, newInputValue) => {
            if (field?.free_solo === "true") {
              handleChange(
                { target: { value: newInputValue } },
                sectionIndex,
                fieldIndex
              );
            }
          }}
          onChange={(event, newValue) => {
            handleChange(
              { target: { value: newValue } },
              sectionIndex,
              fieldIndex
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={field.name + (field.required ? "*" : "")}
              error={hasError}
              disabled={chatbotLoading || conversationOver || disabled}
              size={isSmall ? "small" : "medium"}
              sx={{
                backgroundColor: inverseBackground
                  ? "background.dark"
                  : "background.default",
                borderRadius: "12px",
              }}
            />
          )}
        />
      );

    case "autocomplete-endpoint":
      return (
        <>
          <Autocomplete
            freeSolo={field?.free_solo === "true"}
            options={options}
            value={field.value}
            inputValue={inputValue}
            disabled={chatbotLoading || conversationOver || disabled}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              debouncedSearch(newInputValue);
              if (field?.free_solo === "true") {
                handleChange(
                  { target: { value: newInputValue } },
                  sectionIndex,
                  fieldIndex
                );
              }
            }}
            onChange={(event, newValue) => {
              handleChange(
                { target: { value: newValue } },
                sectionIndex,
                fieldIndex
              );
            }}
            filterOptions={(options) => options}
            noOptionsText={
              inputValue?.length >= 3
                ? "No results found"
                : "Type at least 3 characters to search"
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.name + (field.required ? "*" : "")}
                error={hasError}
                disabled={chatbotLoading || conversationOver || disabled}
                size={isSmall ? "small" : "medium"}
                sx={{
                  backgroundColor: inverseBackground
                    ? "background.dark"
                    : "background.default",
                  borderRadius: "12px",
                }}
              />
            )}
          />
        </>
      );

    default:
      return null;
  }
};

const FormDisplay = ({
  formConfig,
  setFormConfig,
  handleFormUpdate,
  chatbotLoading,
  conversationOver,
  disabled = false,
  selectedVersion,
  setIsModified,
  inverseBackground,
  isSmall,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (formConfig && formConfig.length > 0) {
      setFadeIn(true);
    }
    console.log("formConfig", formConfig);
  }, [formConfig]);

  const visibleSections = getVisibleSections(formConfig);

  const handleChange = (e, sectionIndex, fieldIndex) => {
    const { value } = e.target;
    const updatedFormConfig = [...formConfig];
    console.log("updatedFormConfig", updatedFormConfig);

    console.log("sectionIndex", sectionIndex);
    console.log("fieldIndex", fieldIndex);
    const field = updatedFormConfig[sectionIndex].fields[fieldIndex];

    console.log("field", field);
    if (
      field.type === "table" &&
      field.rowIndex !== undefined &&
      field.columnIndex !== undefined
    ) {
      updatedFormConfig[sectionIndex].fields[fieldIndex].value[field.rowIndex][
        field.columnIndex
      ] = value;
    } else {
      updatedFormConfig[sectionIndex].fields[fieldIndex].value = value;
    }

    // Clear the error for the modified field
    const updatedErrors = { ...errors };
    if (
      updatedErrors[sectionIndex] &&
      updatedErrors[sectionIndex][fieldIndex]
    ) {
      delete updatedErrors[sectionIndex][fieldIndex];
      if (Object.keys(updatedErrors[sectionIndex]).length === 0) {
        delete updatedErrors[sectionIndex];
      }
    }

    setErrors(updatedErrors);
    setFormConfig(updatedFormConfig);
    handleFormUpdate(updatedFormConfig);
    setIsModified(true);
    console.log("updatedFormConfig", updatedFormConfig);

    // Removed fadeIn toggling to prevent re-renders that cause focus loss
    // setFadeIn(false);
    // setTimeout(() => setFadeIn(true), 100);
  };

  const handleTableRowsChange = (sectionIndex, fieldIndex, updatedRows) => {
    const updatedFormConfig = [...formConfig];
    updatedFormConfig[sectionIndex].fields[fieldIndex].value = updatedRows;
    setFormConfig(updatedFormConfig);
    handleFormUpdate(updatedFormConfig);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorFields([]);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 0,
        }}
      >
        {/* <Box sx={{ width: "100%", mt: 1 }}>
          {chatbotLoading && (
            <LinearProgress sx={{ width: "70%", mx: "auto", height: 5 }} />
          )}
        </Box> */}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          px: 4,
          py: 0,
          mb: 0,
        }}
      >
        <form>
          {formConfig.map((section, sectionIndex) => {
            const visibleTitles = visibleSections.map((s) => s.title);
            const isVisible = visibleTitles.includes(section.title);
            if (!isVisible) {
              return null;
            }
            return (
              <Fade in={fadeIn} timeout={1000} key={sectionIndex}>
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                    {section.title}
                  </Typography>
                  <Grid container spacing={2}>
                    {section.fields.map((field, fieldIndex) => {
                      const hasError =
                        errors[sectionIndex] &&
                        errors[sectionIndex][fieldIndex];

                      return (
                        <Fade in={fadeIn} timeout={500} key={fieldIndex}>
                          <Grid
                            item
                            xs={12}
                            sm={
                              field.type === "textarea" ||
                              field.type === "table" ||
                              (field.name && field.name.length > 50)
                                ? 12
                                : 6
                            }
                          >
                            {field.info ? (
                              <Tooltip
                                title={
                                  <Typography
                                    variant="small"
                                    sx={{ whiteSpace: "pre-line" }}
                                    dangerouslySetInnerHTML={{
                                      __html: field.info,
                                    }}
                                  />
                                }
                                arrow
                                placement="left-start"
                              >
                                <span>
                                  <InputField
                                    field={field}
                                    sectionIndex={sectionIndex}
                                    fieldIndex={fieldIndex}
                                    handleChange={handleChange}
                                    handleTableRowsChange={
                                      handleTableRowsChange
                                    }
                                    chatbotLoading={chatbotLoading}
                                    conversationOver={conversationOver}
                                    disabled={disabled}
                                    hasError={hasError}
                                    inverseBackground={inverseBackground}
                                    isSmall={isSmall}
                                  />
                                </span>
                              </Tooltip>
                            ) : (
                              <InputField
                                field={field}
                                sectionIndex={sectionIndex}
                                fieldIndex={fieldIndex}
                                handleChange={handleChange}
                                handleTableRowsChange={handleTableRowsChange}
                                chatbotLoading={chatbotLoading}
                                conversationOver={conversationOver}
                                disabled={disabled}
                                hasError={hasError}
                                inverseBackground={inverseBackground}
                                isSmall={isSmall}
                              />
                            )}
                          </Grid>
                        </Fade>
                      );
                    })}
                  </Grid>
                </Box>
              </Fade>
            );
          })}
        </form>
      </Box>
    </>
  );
};

export default FormDisplay;
