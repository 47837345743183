import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const loginWithMicrosoftUrl = `${API_URL}/auth/login/microsoft`;
export const loginWithOktaUrl = `${API_URL}/auth/login/okta`;

export const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Include credentials (cookies) with requests
});

// Add a response interceptor to handle 401 errors globally
axiosInstance.interceptors.response.use(
  (response) => response, // Return the response if it's successful
  (error) => {
    console.log("Error: ", error);
    if (error.response && error.response.status === 401) {
      console.log("Redirecting to login page");
      console.log(
        "Error: ",
        error.response.data.redirect,
        window.location.pathname
      );
      // Redirect to the login page
      if (
        error.response.data.redirect &&
        error.response.data.redirect !== window.location.pathname &&
        !window.location.pathname.includes("/login")
      ) {
        window.location.href = error.response.data.redirect;
      }
    }

    return Promise.reject(error); // Reject the promise if an error occurs
  }
);
