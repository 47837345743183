import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  CircularProgress,
  LinearProgress,
  Dialog,
  Alert,
  AlertTitle,
  Divider,
  Pagination,
  Paper,
} from "@mui/material";
import { updateRequest, cancelRequest } from "../../api/chat";
import FileAttachments from "../Chat/Files/FileAttachments";
import { useTheme } from "@mui/material/styles";
import FlowDiagram from "../Chat/Diagrams/FlowDiagram";
import FormDisplay from "./FormDisplay";
import { getErrors } from "../../utils/formUtils";
const RequestManager = ({
  context,
  setContext,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  chatbotLoading,
}) => {
  const [localFormConfig, setLocalFormConfig] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(
    context.manage_request.open_request.forms.length
  );
  const [isModified, setIsModified] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(""); // "update" or "cancel"

  const theme = useTheme();

  useEffect(() => {
    if (context.manage_request) {
      const forms = context.manage_request.open_request.forms;
      setSelectedVersion(forms.length);
      const selectedForm = forms[selectedVersion - 1]?.form || [];
      setLocalFormConfig(selectedForm);
      setFadeIn(true);
    }
  }, [context.manage_request.open_request.forms, selectedVersion]);

  const handleVersionChange = (event, value) => {
    setSelectedVersion(value);
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { newErrors, newErrorFields } = getErrors(localFormConfig);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setErrorFields(newErrorFields);
      setOpenDialog(true);
      setLoading(false);
      return;
    }

    try {
      const response = await updateRequest(
        context.manage_request.open_request.id,
        localFormConfig,
        allFiles.map((file) => file.name)
      );
      if (response.success) {
        console.log("Form Updated: ", localFormConfig);
        setContext({
          ...context,
          manage_request: {
            ...context.manage_request,
            open_request: response.updated_request,
          },
        });
        setSelectedVersion(response.updated_request.forms.length);
        setConversationOver(true);
        setDialogMessage(
          "Form updated successfully. You will be redirected to home in a few seconds."
        );
        setDialogTitle("Update Successful!");
        setOpenDialog(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        console.error("Error updating form:", response.message);
        setDialogMessage(response.message);
        setDialogTitle("Error updating form!");
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setDialogMessage("Error submitting form. Please try again.");
      setDialogTitle("Error submitting form!");
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorFields([]);
  };

  const handleSubmitCancel = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await cancelRequest(
        context.manage_request.open_request.id
      );
      if (response.success) {
        console.log(
          "Request Cancelled: ",
          context.manage_request.open_request.id
        );
        setContext({
          ...context,
          manage_request: {
            ...context.manage_request,
            open_request: response.updated_request,
          },
        });
        setDialogMessage(
          "The request has been cancelled successfully. You will be redirected to home in a few seconds."
        );
        setDialogTitle("Request cancelled!");
        setOpenDialog(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        setConversationOver(true);
      } else {
        console.error("Error cancelling the request:", response.message);
        setDialogMessage(response.message);
        setDialogTitle("Error cancelling the request!");
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error cancelling the request:", error);
      setDialogMessage("Error cancelling the request. Please try again.");
      setDialogTitle("Error cancelling the request!");
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAction = (e) => {
    setConfirmDialogOpen(false);
    if (dialogType === "cancel") {
      handleSubmitCancel(e);
    } else if (dialogType === "update") {
      handleSubmitUpdate(e);
    }
  };

  return (
    <Box
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.dark",
        color: "text.main",
        p: 2,

        pb: 2,
        overflow: "hidden",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          p: 1,
          height: "40%",
        }}
        elevation={0}
      >
        {context.manage_request.open_request && (
          <FlowDiagram
            user={context.manage_request.open_request.requester}
            steps={context.manage_request.open_request.steps}
            theme={theme}
            onNodeClick={(node) => {
              console.log(node);
            }}
            selectedNodeTitle={
              context.manage_request.open_request?.current_approval?.approval
                ?.name
            }
          />
        )}
      </Paper>

      <Paper sx={{ height: "60%", mt: 2 }} elevation={0}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            mb: 0,
            px: 4,
            pb: 0,
            height: "10%",
          }}
        >
          <Typography variant="subtitle1">Form Versions:</Typography>
          <Pagination
            count={
              context.manage_request.open_request.forms
                ? context.manage_request.open_request.forms.length
                : 1
            }
            page={selectedVersion}
            onChange={handleVersionChange}
            color="primary"
            siblingCount={1}
            boundaryCount={1}
          />
        </Box>
        <Divider sx={{ mx: 2, mb: 0, pb: 0 }} />
        <Box
          sx={{
            height: "90%",
            display: "flex",
            flexDirection: "column",

            color: "text.main",
            pt: 0,
            pb: 2,
            overflow: "hidden",
          }}
        >
          {(() => {
            try {
              return (
                <FormDisplay
                  formConfig={localFormConfig}
                  setFormConfig={setLocalFormConfig}
                  handleFormUpdate={() => {}}
                  chatbotLoading={chatbotLoading}
                  disabled={
                    loading ||
                    conversationOver ||
                    selectedVersion !==
                      context.manage_request.open_request.forms.length
                  }
                  setIsModified={setIsModified}
                  isSmall={true}
                  inverseBackground={true}
                />
              );
            } catch (error) {
              console.error("Error rendering FormDisplay:", error);
              return null;
            }
          })()}

          <Box
            sx={{
              width: "90%",
              mx: "auto",
              my: 1,
            }}
          >
            <FileAttachments
              selectedFiles={[]}
              setSelectedFiles={() => {}}
              // theme={theme}
            />
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  setDialogType("cancel");
                  setConfirmDialogOpen(true);
                }}
                disabled={
                  loading ||
                  conversationOver ||
                  context.manage_request.open_request.status === "cancelled"
                }
              >
                Cancel Request
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                onClick={() => {
                  setDialogType("update");
                  setConfirmDialogOpen(true);
                }}
                disabled={loading || conversationOver || !isModified}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : isModified ? (
                  "Update Request"
                ) : (
                  "Modify Fields to Update"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ p: 10 }}>
        <Alert
          severity={dialogType === "update" ? "success" : "error"}
          sx={{ p: 2 }}
        >
          <AlertTitle>{dialogTitle}</AlertTitle>
          {dialogMessage || "Please fill up the following fields:"}
          <ul>
            {errorFields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </Alert>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <Alert
          severity={dialogType === "update" ? "success" : "error"}
          sx={{ pr: 4, py: 2 }}
        >
          <AlertTitle sx={{ mb: 1 }}>
            {dialogType === "update"
              ? "Confirm Update"
              : "Confirm Cancellation"}
          </AlertTitle>
          {dialogType === "update"
            ? "Are you sure you want to update the request?"
            : "Are you sure you want to cancel the request?"}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setConfirmDialogOpen(false)} color="inherit">
              No
            </Button>
            <Button onClick={handleConfirmAction} color="primary" autoFocus>
              Yes
            </Button>
          </Box>
        </Alert>
      </Dialog>
    </Box>
  );
};

export default RequestManager;
