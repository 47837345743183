import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileAttachments from "./Files/FileAttachments";

const MessageInput = ({
  user,
  input,
  setInput,
  sendMessage,
  chatbotLoading,
  theme,
  selectedFiles,
  setSelectedFiles,
  conversationOver,
  allFiles,
  setAllFiles,
  inputRef, // Receive the ref from Chatbot.js
  noMessage,
  initialOptions,
}) => {
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState([]);

  const handleSend = () => {
    sendMessage(input, selectedFiles);
    setInput("");
    setSelectedFiles([]);
    setAllFiles([...allFiles, ...selectedFiles]);
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      (input.trim() !== "" || selectedFiles.length > 0)
    ) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).filter(
      (file) => file.type === "application/pdf"
    );

    // Check for duplicate file names
    const existingFileNames = selectedFiles.map((file) => file.name);
    const duplicates = newFiles.filter((file) =>
      existingFileNames.includes(file.name)
    );

    if (duplicates.length > 0) {
      setDuplicateFiles(duplicates.map((file) => file.name));
      setOpenDuplicateModal(true);
    }

    // Filter out duplicates before adding
    const uniqueFiles = newFiles.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    // Limit the total number of files to 3
    if (selectedFiles.length + uniqueFiles.length > 3) {
      uniqueFiles.splice(3 - selectedFiles.length);
    }

    setSelectedFiles([...selectedFiles, ...uniqueFiles]);

    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal(false);
    setDuplicateFiles([]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        justifyContent: "flex-start",
        height: noMessage ? "80%" : "auto",
        width: "100%",
      }}
    >
      {noMessage && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            mb: 4,
            width: "100%",
          }}
        >
          {user?.organization_id && (
            <img
              src={`/clients/${user?.organization_id}.png`}
              alt={`${user?.organization_id}.png`}
              onError={(e) => {
                console.log(
                  "Error loading vendor image: ",
                  `/clients/${user?.organization_id}.png`
                );
                // e.target.onerror = null;
                // e.target.src = "/clients/Default.svg";
              }}
              style={{ height: 128, width: "auto" }}
            />
          )}

          <Typography
            variant="h4"
            sx={{
              width: "100%",
              mt: 12,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {user?.name
              ? `Hi, ${user?.name.split(" ")[0]}! What can I help with?`
              : "Hi! What can I help with?"}
          </Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", alignItems: "center", mb: 2, width: "100%" }}>
        <input
          accept="application/pdf"
          style={{ display: "none" }}
          id="file-upload"
          multiple
          type="file"
          onChange={handleFileChange}
          disabled={selectedFiles.length >= 3 || chatbotLoading}
        />
        <label htmlFor="file-upload">
          <Tooltip
            title={
              selectedFiles.length >= 3
                ? "Please upload new files in a separate message."
                : "Upload PDF files"
            }
          >
            <span>
              <IconButton
                component="span"
                disabled={selectedFiles.length >= 3 || chatbotLoading}
              >
                <AttachFileIcon />
              </IconButton>
            </span>
          </Tooltip>
        </label>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Message Optimized AI"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          multiline
          disabled={chatbotLoading}
          maxRows={5}
          inputRef={inputRef}
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSend}
                disabled={
                  (!input.trim() && selectedFiles.length === 0) ||
                  chatbotLoading
                }
                sx={{
                  minWidth: 0,
                  padding: 1,
                  borderRadius: "50%",
                }}
              >
                <SendIcon />
              </Button>
            ),
            sx: {
              backgroundColor: theme.palette.background.dark,
              border: "none",
              width: "100%",
              margin: "0 auto",
              py: 1,
              px: 2,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "32px",
            },
            "& .MuiInputBase-root": {
              mx: 0,
            },
          }}
        />

        {/* Modal for Duplicate Files */}
        <Dialog open={openDuplicateModal} onClose={handleCloseDuplicateModal}>
          <DialogTitle>Duplicate File Detected</DialogTitle>
          <DialogContent>
            <Typography>The following file(s) are already attached:</Typography>
            <ul>
              {duplicateFiles.map((fileName, idx) => (
                <li key={idx}>{fileName}</li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDuplicateModal} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {noMessage && (
        <FileAttachments
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          theme={theme}
        />
      )}

      {noMessage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap", // Allow options to wrap if needed
            width: "100%",
            mt: 2, // Add some margin-top for spacing
          }}
        >
          {initialOptions.map((option, index) => (
            <Button
              key={index}
              onClick={() => sendMessage(option.message, [])}
              variant="outlined"
              sx={{
                p: 2,
                mx: 3,
                my: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "25%", // Set width to 20%
                minHeight: "rem", // Set height to 25%
                textTransform: "none", // Add this line to prevent text capitalization
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {option.icon}
                <Typography
                  variant="body1"
                  sx={{
                    ml: 1, // Add some margin-left for spacing between icon and text
                    textAlign: "left",
                  }}
                >
                  {option.description}
                </Typography>
              </Box>
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MessageInput;
