import React, { useEffect } from "react";
import { Container, Typography, Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { onboardUser } from "../../api/auth";

const SignedUp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const onboard = async () => {
      try {
        const response = await onboardUser();
        console.log("ONBOARDING RESPONSE: ", response);
        if (response.status === 200) {
          navigate("/dashboard");
        } else {
          console.error("Error during onboarding:", response.status);
        }
      } catch (error) {
        console.error("Error during onboarding:", error);
      }
    };

    onboard();
  }, [navigate]);

  // useEffect(() => {
  //   const poll = async () => {
  //     try {
  //       const response = await pollIsNew();

  //       if (response.data.is_new === false) {
  //         navigate("/dashboard");
  //       } else {
  //         setTimeout(poll, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error polling is_new:", error);
  //     }
  //   };

  //   poll();
  // }, [navigate]);

  return (
    <Container
      maxWidth="md"
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      sx={{
        height: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container
        component={motion.div}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.0 }}
        sx={{ width: "100%" }}
      >
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Typography variant="h2" align="center" gutterBottom sx={{ mt: 5 }}>
            Welcome to Optimized
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 1.5 }}
        >
          <Typography variant="h5" align="center" sx={{ mt: 5 }}>
            We are preparing your account. This usually takes a few minutes...
          </Typography>
        </motion.div>

        <Box
          component={motion.div}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 2.5 }}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <LinearProgress
            color="primary"
            sx={{ width: "30%", mt: 5, borderRadius: 2 }}
          />
        </Box>
      </Container>
    </Container>
  );
};
export default SignedUp;
