import { useState, useEffect } from "react";
import { initializeChat } from "../api/chat";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import QuizIcon from "@mui/icons-material/Quiz";

const useChat = () => {
  const [chatbotLoading, setChatbotLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activePolicy, setActivePolicy] = useState(null);
  const [conversationOver, setConversationOver] = useState(false);
  const [initialOptions, setInitialOptions] = useState([]);
  const [context, setContext] = useState({});

  useEffect(() => {
    const initializeAgent = async () => {
      try {
        const { initialPolicy, initialOptions, initialContext } =
          await initializeChat();

        console.log("Initial Options: ", initialOptions);
        const optionsWithIcons = initialOptions.map((option) => {
          let iconComponent;
          switch (option.icon) {
            case "ShoppingCartIcon":
              iconComponent = <ShoppingCartIcon color="error" />;
              break;
            case "QuizIcon":
              iconComponent = <QuizIcon color="warning" />;
              break;
            case "StoreIcon":
              iconComponent = <StoreIcon color="success" />;
              break;
            default:
              iconComponent = null;
          }
          return { ...option, icon: iconComponent };
        });

        setActivePolicy(initialPolicy);
        setInitialOptions(optionsWithIcons);
        setContext(initialContext);
        console.log("Active Policy: ", initialPolicy);
      } catch (error) {
        console.error("Error fetching initial policy:", error);
      }
    };

    initializeAgent();
  }, []);

  return {
    messages,
    setMessages,
    drawerOpen,
    setDrawerOpen,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    initialOptions,
    setInitialOptions,
    context,
    setContext,
    chatbotLoading,
    setChatbotLoading,
  };
};

export default useChat;
