import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Modal,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import FileAttachments from "./Files/FileAttachments";
import toolRunningMessages from "../../utils/toolRunningMessages.json";
import { sendMessageToChatbot } from "../../api/chat";

import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

function SpeedDialTooltipOpen({ actions }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{
        position: "absolute",
        top: 16,
        left: 16,
        "& .MuiSpeedDialAction-staticTooltipLabel": { mx: 0 },
      }}
      FabProps={{ size: "small", alignItems: "center" }}
      icon={<SpeedDialIcon />}
      direction="right"
      size="small"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          sx={{ m: 0, p: 0 }}
        />
      ))}
    </SpeedDial>
  );
}

const Chatbot = ({
  user,
  context,
  setContext,
  setLatestUpdateId,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  messages,
  setMessages,
  activePolicy,
  setActivePolicy,
  initialOptions,
  chatbotLoading,
  setChatbotLoading,
  handleLogout,
}) => {
  const [input, setInput] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dragCounter = useRef(0);

  const theme = useTheme();
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null); // Reference to the input field

  const [dragging, setDragging] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Drag and Drop Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    dragCounter.current = 0;

    const droppedFiles = Array.from(e.dataTransfer.files);

    // Separate PDF and non-PDF files
    const pdfFiles = droppedFiles.filter(
      (file) => file.type === "application/pdf"
    );
    const nonPdfFiles = droppedFiles.filter(
      (file) => file.type !== "application/pdf"
    );

    console.log("PDF FILES: ", pdfFiles);
    console.log("NON PDF FILES: ", nonPdfFiles);

    // Handle non-PDF files
    if (nonPdfFiles.length > 0) {
      setErrorMessage("Only PDF files are allowed.");
      // Early return if desired
      // return;
    }

    // Check for duplicate file names
    const existingFileNames = selectedFiles.map((file) => file.name);
    const duplicates = pdfFiles.filter((file) =>
      existingFileNames.includes(file.name)
    );

    if (duplicates.length > 0) {
      // const duplicateNames = duplicates.map((file) => file.name).join(", ");
      setErrorMessage(
        <>
          <Typography variant="h6" color="text.primary" fontWeight="bold">
            The following file(s) are already attached:
          </Typography>
          <ul>
            {duplicates.map((file) => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul>
        </>
      );
    }

    // Filter out duplicates before adding
    const uniqueFiles = pdfFiles.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    // Check for file upload limit
    if (selectedFiles.length + uniqueFiles.length > 3) {
      setOpenModal(true);
      return;
    }

    // Update the selected files
    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);

    // Focus the input field
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const sendMessage = async (message, files) => {
    const currentUpdateId = Date.now();
    setLatestUpdateId(currentUpdateId);
    setChatbotLoading(true);

    let newMessages = [
      {
        role: "user",
        content: message,
        files: files.map((file) => ({ name: file.name, type: file.type })),
      },
    ];
    let allNewMessages = [...messages, ...newMessages];

    setMessages(allNewMessages);

    const formData = new FormData();
    formData.append("newMessages", JSON.stringify(newMessages));

    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });
    }

    formData.append("activePolicy", activePolicy);
    formData.append("context", JSON.stringify(context));

    let isConversationOver = conversationOver; // Initialize local variable

    try {
      let continueSending = true;
      let currentActivePolicy = activePolicy;

      while (continueSending) {
        continueSending = false;

        const { newMessages, isDone, newActivePolicy, newContext } =
          await sendMessageToChatbot(formData);

        console.log("NEW ACTIVE AGENT: ", newActivePolicy);
        console.log("isDone: ", isDone);
        console.log("NEW CONTEXT: ", newContext);

        if (isDone) {
          setConversationOver(true);
          isConversationOver = true; // Update local variable
        }

        if (newActivePolicy && newActivePolicy !== currentActivePolicy) {
          setActivePolicy(newActivePolicy);
          currentActivePolicy = newActivePolicy;
          formData.set("activePolicy", newActivePolicy);
          console.log("Current active agent: ", currentActivePolicy);
          continueSending = true;
        }

        setContext(newContext);

        allNewMessages = [...allNewMessages, ...newMessages];
        setMessages(allNewMessages);
        console.log("ALL NEW MESSAGES: ", allNewMessages);

        formData.set("newMessages", JSON.stringify([]));
        formData.set("context", JSON.stringify(newContext));

        const lastMessage = newMessages[newMessages.length - 1];

        if (lastMessage && lastMessage.role == "tool") {
          continueSending = true;
        }
      }
      setChatbotLoading(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setChatbotLoading(false);
      setSelectedFiles([]);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (!chatbotLoading && inputRef.current) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // Adjust the delay as needed
    }
  }, [chatbotLoading]);

  return (
    <Box
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        transition: "background-color 0.3s, border 0.3s",
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {messages.length > 0 && (
        <MessageList
          messages={messages}
          toolRunningMessages={toolRunningMessages}
          theme={theme}
          messagesEndRef={messagesEndRef}
          chatbotLoading={chatbotLoading}
          sendMessage={sendMessage}
          submittedSuccessfully={false} // Adjust as needed
          // initialOptions={initialOptions}
        />
      )}
      {conversationOver ? (
        <Alert severity="success" variant="filled" sx={{ mb: 2, mt: 2 }}>
          <AlertTitle>Conversation Over</AlertTitle>
          Your conversation with the chatbot is over. You can close this window
          now.
        </Alert>
      ) : (
        <>
          {messages.length > 0 && (
            <FileAttachments
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              theme={theme}
            />
          )}

          <MessageInput
            user={user}
            noMessage={messages.length === 0}
            input={input}
            setInput={setInput}
            sendMessage={sendMessage}
            chatbotLoading={chatbotLoading}
            theme={theme}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            conversationOver={conversationOver}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
            inputRef={inputRef} // Pass the ref to focus the input
            initialOptions={initialOptions}
          />
        </>
      )}

      {/* Modal for File Upload Limit Exceeded */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          You can only upload up to 3 files at a time. Please upload new files
          in a separate message.
        </DialogTitle>
      </Dialog>

      {/* General Error Modal */}
      <Dialog open={Boolean(errorMessage)} onClose={() => setErrorMessage("")}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage("")} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Overlay for Dragging Files */}
      <Modal
        open={dragging}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.default",
            // border: "2px solid #000",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PictureAsPdfRoundedIcon
              sx={{ fontSize: 64, color: "error.main" }}
            />
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              color="text.primary"
              sx={{ mt: 2 }}
              fontWeight="bold"
            >
              Add any PDF File
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Drop a PDF file here to add it to the chat.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Chatbot;
