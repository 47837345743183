import React, { useEffect, useRef } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
} from "@xyflow/react";

import { Box, Typography } from "@mui/material";
import "@xyflow/react/dist/style.css";
import StepNode from "./StepNode";
import { getStepNodeData } from "./Utils";
const nodeWidth = 20 * 16;
const nodeHeight = 10 * 16;

const getLayoutedElements = (nodes, edges) => {
  const horizontalSpacing = nodeWidth + 50; // Adjust spacing as needed
  const verticalSpacing = nodeHeight + 20; // Adjust spacing as needed

  nodes.forEach((node) => {
    const [_, groupIdx, stepIdx] = node.id.split("-");
    const groupNodes = nodes.filter((n) =>
      n.id.startsWith(`step-${groupIdx}-`)
    );
    const groupHeight = (groupNodes.length - 1) * verticalSpacing;

    node.position = {
      x: groupIdx * horizontalSpacing,
      y: stepIdx * verticalSpacing - groupHeight / 2,
    };
    node.targetPosition = "left";
    node.sourcePosition = "right";
  });

  return { nodes, edges };
};

const nodeTypes = { stepNode: StepNode };

function FLD({ user, steps, theme, onNodeClick, selectedNodeTitle }) {
  const { fitView, setViewport } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  // 1) On mount (or first time) create and layout the nodes/edges
  useEffect(() => {
    if (!steps?.length) {
      setNodes([]);
      setEdges([]);
      return;
    }

    // 0) Filter out step items that are not required
    let filteredSteps = steps
      .map((stepGroup) => stepGroup.filter((step) => step.required))
      .filter((stepGroup) => stepGroup.length > 0);

    console.log("filteredSteps", filteredSteps);

    // Build out base nodes and edges
    const generatedNodes = [];
    const generatedEdges = [];
    filteredSteps.forEach((stepGroup, groupIndex) => {
      stepGroup.forEach((step, stepIndex) => {
        const nodeId = `step-${groupIndex}-${stepIndex}`;
        generatedNodes.push({
          id: nodeId,
          data: getStepNodeData(step, nodeWidth, nodeHeight),
          type: "stepNode",
          draggable: false,
        });

        if (groupIndex > 0) {
          const prevGroup = filteredSteps[groupIndex - 1];
          prevGroup.forEach((_, prevIndex) => {
            const sourceId = `step-${groupIndex - 1}-${prevIndex}`;
            const targetId = nodeId;
            generatedEdges.push({
              id: `${sourceId}-${targetId}`,
              source: sourceId,
              target: targetId,
              type: "step",
              animated: true,
              style: {
                stroke: theme.palette.success.main,
                strokeWidth: 2,
              },
            });
          });
        }
      });
    });

    const layoutedElements = getLayoutedElements(
      generatedNodes,
      generatedEdges
    );
    setNodes(layoutedElements.nodes);
    setEdges(layoutedElements.edges);

    const firstEnabledNode = layoutedElements.nodes.find(
      (node) => node.data.title === selectedNodeTitle
    );

    console.log("firstEnabledNode", firstEnabledNode, layoutedElements.nodes);

    if (firstEnabledNode) {
      console.log(
        "firstEnabledNode",
        firstEnabledNode.position.x,
        firstEnabledNode.position.y
      );

      // Define the zoom level
      let zoom = 0.5;

      // Obtain the dimensions of the ReactFlow container
      const container = document.querySelector(".react-flow");
      if (container) {
        const { width, height } = container.getBoundingClientRect();

        // Calculate the viewport's x and y to center the node
        const x =
          -firstEnabledNode.position.x * zoom +
          width / 2 -
          (nodeWidth * zoom) / 2;
        const y =
          -firstEnabledNode.position.y * zoom +
          height / 2 -
          (nodeHeight * zoom) / 2;

        setViewport({
          x: x,
          y: y,
          zoom: zoom,
        });
      } else {
        // Fallback if container is not found
        setViewport({
          x: -firstEnabledNode.position.x * zoom,
          y: -firstEnabledNode.position.y * zoom,
          zoom: zoom,
        });
      }
    }
  }, [steps, setNodes, setEdges, theme]);

  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        border: `1px solid ${theme.palette.background.dark}`,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        minZoom={0.1}
        maxZoom={0.8}
        attributionPosition="top-right"
        onNodeClick={(event, node) => onNodeClick(node.data)}

        // defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls showInteractive={false} style={{ flexDirection: "row" }} />
      </ReactFlow>
    </Box>
  );
}

export default function FlowDiagram({
  user,
  steps,
  theme,
  onNodeClick,
  selectedNodeTitle,
}) {
  return (
    <ReactFlowProvider>
      <FLD
        user={user}
        steps={steps}
        theme={theme}
        onNodeClick={onNodeClick}
        selectedNodeTitle={selectedNodeTitle}
      />
    </ReactFlowProvider>
  );
}
