import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const theme = createTheme({
  typography: {
    fontFamily: "Raleway, Arial",
    h1: {
      fontFamily: "Inter, Arial",
      fontSize: "3.998rem", // 1.333^6 * 1rem
      lineHeight: 1.6,
    },
    h2: {
      fontFamily: "Inter, Arial",
      fontSize: "2.998rem", // 1.333^5 * 1rem
      lineHeight: 1.6,
    },
    h3: {
      fontFamily: "Raleway, Arial",
      fontSize: "2.248rem", // 1.333^4 * 1rem
      lineHeight: 1.6,
    },
    h4: {
      fontFamily: "Raleway, Arial",
      fontSize: "1.686rem", // 1.333^3 * 1rem
      lineHeight: 1.6,
    },
    h5: {
      fontFamily: "Raleway, Arial",
      fontSize: "1.266rem", // 1.333^2 * 1rem
      lineHeight: 1.6,
    },
    h6: {
      fontFamily: "Raleway, Arial",
      fontSize: "0.95rem", // 1.333^1 * 1rem
      lineHeight: 1.6,
    },
    body: {
      fontFamily: "Raleway, Arial",
      fontSize: "0.875rem", // Adjusted base font size
      lineHeight: 1.6, // Increased line spacing
    },
    subtitle: {
      fontFamily: "Raleway, Arial",
      fontSize: "0.6rem", // Adjusted base font size
      lineHeight: 1.6, // Increased line spacing
    },
  },
  palette: {
    text: {
      main: "#18181B",
      disabled: "#18181B80",
    },
    primary: {
      main: "#274754",
    },
    success: {
      main: "#2A9D90",
    },
    error: {
      main: "#E76E50",
    },
    warning: {
      main: "#E8C468",
    },
    info: {
      main: "#0097D5",
    },
    background: {
      default: "#FFFFFF",
      dark: "#F4F4F4",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Disable elevation
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: "24px", // Make the text field rounded
            "& fieldset": {
              border: "none", // Remove the border
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Disable elevation
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0, // Remove the border
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "0.875rem", // Match the body font size
          lineHeight: 1.6, // Match the body line height
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem", // Match the body font size
          lineHeight: 1.6, // Match the body line height
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          "& a": {
            color: "#ADD8E6", // Light blue color for links
          },
        },
      },
    },
  },
});

export default theme;
