import { Box, Typography } from "@mui/material";

export const getStepNodeData = (
  step,
  nodeWidth,
  nodeHeight,
  selectedNodeTitle
) => {
  if (!nodeWidth) {
    nodeWidth = "20rem";
  }
  if (!nodeHeight) {
    nodeHeight = "6rem";
  }

  return {
    id: step.id,
    title: step.name,
    icon: step.status,
    disabled: selectedNodeTitle && step.name !== selectedNodeTitle,
    status: step.status,
    approvers: step.approvers,
    date: step.date,
    tasks: step.tasks,
    content: (
      <Box display="flex" justifyContent="space-between">
        <Typography>
          {step?.approvers?.length > 1
            ? `${step.approvers[0].name} (+${step.approvers.length - 1})`
            : step?.approvers?.[0]?.name || "None"}
        </Typography>
        <Typography>
          {new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
          }).format(new Date(step.date))}
        </Typography>
      </Box>
    ),
    nodeWidth,
    nodeHeight,
  };
};
