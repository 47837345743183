import { axiosInstance } from "./general";

export const logout = async () => {
  try {
    await axiosInstance.get(`/auth/logout`);
    window.location.href = "/";
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

export const fetchUser = async () => {
  try {
    const response = await axiosInstance.get(`/auth/get_user`);
    console.log("USER ", response.data);

    if (response.data && response.data.email) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching user: ", error.response);
    throw error;
  }
};

export const onboardUser = async () => {
  const response = await axiosInstance.post(`/auth/onboard`, {});
  return response;
};

export const isLoggedIn = async () => {
  const response = await axiosInstance.get(`/auth/is_logged_in`);
  return response.data.is_logged_in;
};
