import React from "react";
import { Handle, Position } from "@xyflow/react";
import { Box, Typography, List, ListItem, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/DoneRounded";
import InProgressIcon from "@mui/icons-material/HourglassEmptyRounded";
import NewIcon from "@mui/icons-material/FiberNewRounded";
import CancelIcon from "@mui/icons-material/DeleteForeverOutlined";
import BypassIcon from "@mui/icons-material/AirlineStopsRounded";
import UnknownIcon from "@mui/icons-material/HelpRounded";
import RejectedIcon from "@mui/icons-material/ReportGmailerrorredRounded";

const snakeToNormalCase = (text) => {
  return text
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

function StepNode({ data }) {
  const theme = useTheme();

  const getIcon = (icon) => {
    const iconSize = 36;

    switch (icon) {
      case "new":
        return (
          <NewIcon
            sx={{ color: theme.palette.info.main, fontSize: iconSize }}
          />
        );
      case "in_progress":
        return (
          <InProgressIcon
            sx={{ color: theme.palette.warning.main, fontSize: iconSize }}
          />
        );
      case "complete":
        return (
          <DoneIcon
            sx={{ color: theme.palette.success.main, fontSize: iconSize }}
          />
        );
      case "rejected":
        return (
          <RejectedIcon
            sx={{ color: theme.palette.error.main, fontSize: iconSize }}
          />
        );
      case "cancelled":
        return (
          <CancelIcon
            sx={{ color: theme.palette.error.main, fontSize: iconSize }}
          />
        );
      case "not_applicable":
        return (
          <BypassIcon
            sx={{ color: theme.palette.warning.main, fontSize: iconSize }}
          />
        );
      case "unknown":
        return (
          <UnknownIcon
            sx={{ color: theme.palette.warning.main, fontSize: iconSize }}
          />
        );
      default:
        return null;
    }
  };

  // const tooltipTitle = data.disabled
  //   ? data.tooltipDisabled || "Click to select this step"
  //   : data.tooltipEnabled || "This step is selected!";
  const tooltipTitle = (
    <span>
      <strong>{snakeToNormalCase(data.status)}</strong>
    </span>
  );

  const renderContent = () => {
    if (!data.content || data.content.length === 0) {
      return <Typography variant="body2"></Typography>;
    }

    return data.content;
  };

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <Box
        sx={{
          backgroundColor: data.disabled
            ? theme.palette.background.dark
            : theme.palette.background.default,
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          width: data.nodeWidth || "15rem",
          minHeight: data.nodeHeight || "6rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(2),
        }}
      >
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {getIcon(data.icon)}
            <Typography
              variant="h5"
              sx={{
                marginLeft: theme.spacing(1),
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textOverflow: "ellipsis",
                maxWidth: "90%",
                minHeight: "4em",
              }}
              title={data.title}
            >
              {data.title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>{renderContent()}</Box>
      </Box>
    </Tooltip>
  );
}

export default StepNode;
