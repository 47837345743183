import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Container,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft"; // Import the icon

import { AppBar, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { loginWithMicrosoftUrl, loginWithOktaUrl } from "../../api/general"; // Import fetchUser
import { isLoggedIn } from "../../api/auth";
import LockIcon from "@mui/icons-material/Lock";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [email, setEmail] = useState(() => {
    // Retrieve the email from localStorage or default to an empty string
    return localStorage.getItem("email") || "";
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(true); // State to handle loading
  const [error, setError] = useState(null); // State to handle errors

  const queryParams = new URLSearchParams(location.search);
  const organizationId = queryParams.get("organization_id");
  // Persist email to localStorage
  useEffect(() => {
    localStorage.setItem("email", email);
  }, [email]);

  // Fetch user on component mount
  useEffect(() => {
    const checkUser = async () => {
      try {
        const ili = await isLoggedIn();
        console.log("ILI: ", ili);
        if (ili) {
          console.log("User is logged in, redirecting to dashboard");
          navigate("/dashboard");
        } else if (organizationId) {
          handleOktaLogin(organizationId);
        }
      } catch (err) {
        console.error("User not logged in:", err);
        if (organizationId) {
          handleOktaLogin(organizationId);
        }
      }
      setIsLoading(false);
    };

    checkUser();
  }, [navigate, queryParams, organizationId]);

  const handleOktaLogin = (organizationId) => {
    window.location.href = `${loginWithOktaUrl}?organization_id=${organizationId}`;
  };

  const openEmailModal = () => {
    setModalOpen(true);
  };

  const closeEmailModal = () => {
    setModalOpen(false);
  };

  const validateEmail = (emailValue) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailValue);
  };

  const submitEmail = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setEmailError("");
    closeEmailModal();
    let organizationId = email.split("@")[1].split(".")[0];
    handleOktaLogin(organizationId);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5">Loading...</Typography>
      </Box>
    );
  }

  // If organizationId exists, do not render the buttons and other elements
  if (organizationId) {
    return null; // Or you can render a different component/message if desired
  }

  // Normal render if no organization_id in query params and user is not logged in
  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        position="fixed"
        sx={{ bgcolor: theme.palette.primary.main }}
        elevation={1}
      >
        <Toolbar>
          {/* <img
            src="/LogoLight.png"
            alt="Optimized Inc Logo"
            style={{
              height: "64px",
              marginRight: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = "/";
            }}
          /> */}
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            OPTIMIZED
          </Typography>
        </Toolbar>
      </AppBar>

      <Container
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* header at top with the logo */}

        <Container sx={{ width: "90%" }}>
          <Typography variant="h3" align="center" gutterBottom>
            Log in to Optimized
            {/* add a large 64x64 bolt icon */}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Button
              href={loginWithMicrosoftUrl}
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
            >
              <MicrosoftIcon sx={{ mr: 1 }} />
              Login with Outlook
            </Button>
          </Box>
          {/* <Box sx={{ mt: 1 }}>
            <Button
              href={loginWithGoogleUrl}
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
            >
              <GoogleIcon sx={{ mr: 1 }} />
              Login with Google
            </Button>
          </Box> */}

          <Box sx={{ mt: 3 }}>
            <Button
              onClick={openEmailModal}
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
            >
              <LockIcon sx={{ mr: 1 }} />
              Login with Okta
            </Button>
          </Box>

          {/* Display error message if any */}
          {error && (
            <Box sx={{ mt: 2 }}>
              <Typography color="error" align="center">
                {error}
              </Typography>
            </Box>
          )}
        </Container>
      </Container>

      <Dialog
        open={isModalOpen}
        onClose={closeEmailModal}
        sx={{
          "& .MuiDialog-paper": {
            width: "600px", // Set the desired width
          },
        }}
      >
        <DialogTitle>Please enter your work email</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitEmail();
              }
            }}
            error={!!emailError}
            helperText={emailError}
            sx={{
              backgroundColor: "background.dark",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={submitEmail} color="primary" sx={{ mb: 1, mr: 2 }}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Login;
