import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogTitle,
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemText,
  DialogActions,
} from "@mui/material";
import EditableTable from "./EditableTable"; // Import the EditableTable component
import { submitForm } from "../../api/chat";
import FileAttachments from "../Chat/Files/FileAttachments";
import { useTheme } from "@mui/material/styles";
import FormDisplay from "./FormDisplay";
import { getErrors } from "../../utils/formUtils";

const IntakeForm = ({
  context,
  setContext,
  conversationOver,
  setConversationOver,
  allFiles,
  setAllFiles,
  chatbotLoading,
}) => {
  const [localFormConfig, setLocalFormConfig] = useState([]);
  const [fadeIn, setFadeIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const theme = useTheme();

  console.log("Intake form rendered", context);

  useEffect(() => {
    if (context.intake) {
      setLocalFormConfig(context.intake.form);
      setFadeIn(true);
    }
  }, [context.intake]);

  const handleFormUpdate = useCallback(
    (updatedFormConfig) => {
      setContext((prevContext) => ({
        ...prevContext,
        intake: { ...prevContext.intake, form: updatedFormConfig },
      }));
    },
    [setContext]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      let { newErrors, newErrorFields } = getErrors(localFormConfig);

      setErrors(newErrors);
      setErrorFields(newErrorFields);

      if (Object.keys(newErrors).length > 0) {
        setDialogMessage("Please fill up the following fields");
        setOpenDialog(true);
        setLoading(false);
        return;
      }

      try {
        const response = await submitForm(
          localFormConfig,
          allFiles.map((file) => file.name)
        );
        if (response.success) {
          setDialogMessage("Request submitted successfully");
          setOpenDialog(true);
          setConversationOver(true);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          setDialogMessage(response.message);
          setOpenDialog(true);
        }
      } catch (error) {
        setDialogMessage("Error submitting form. Please try again.");
        setOpenDialog(true);
      } finally {
        setLoading(false);
      }
    },
    [localFormConfig, allFiles, setConversationOver]
  );

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setErrorFields([]);
    setDialogMessage("");
  }, [setOpenDialog, setErrorFields]);

  return (
    <Box
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.dark",
        color: "text.main",
        pt: 4,
        pb: 2,
        overflow: "hidden",
      }}
    >
      {(() => {
        try {
          return (
            <FormDisplay
              formConfig={localFormConfig}
              setFormConfig={setLocalFormConfig}
              handleFormUpdate={handleFormUpdate}
              chatbotLoading={chatbotLoading}
              setIsModified={() => {}}
            />
          );
        } catch (error) {
          console.error("Error rendering FormDisplay:", error);
          return null;
        }
      })()}
      <Box
        sx={{
          width: "90%",
          mx: "auto",
          mb: 2,
        }}
      >
        <FileAttachments
          selectedFiles={allFiles}
          setSelectedFiles={setAllFiles}
          theme={theme}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          onClick={handleSubmit}
          disabled={loading || conversationOver}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>
      {openDialog && (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ p: 10 }}>
          <Alert
            sx={{ pt: 2 }}
            severity={errorFields.length > 0 ? "error" : "success"}
          >
            <AlertTitle>{dialogMessage}</AlertTitle>

            {errorFields.length > 0 ? (
              <List dense sx={{ maxHeight: 500, overflow: "auto" }}>
                {errorFields.map((field, index) => (
                  <ListItem key={index} sx={{ my: 0 }}>
                    <ListItemText
                      primary={
                        <Typography variant="small">- {field}</Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                A member of our procurement team will review your request and
                contact you shortly. You will be redirected to the homepage in a
                few seconds...
              </Box>
            )}
            {errorFields.length === 0 && (
              <DialogActions>
                <Button
                  variant="text"
                  color="success"
                  size="small"
                  onClick={() => window.location.reload()}
                  sx={{ width: "25%", mt: 2 }}
                >
                  Go to Home
                </Button>
              </DialogActions>
            )}
          </Alert>
        </Dialog>
      )}
    </Box>
  );
};

export default IntakeForm;
