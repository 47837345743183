import React, { useState, useRef, useEffect, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  Drawer,
  Divider,
  Typography,
  Button,
  IconButton,
} from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";
import Tooltip from "@mui/material/Tooltip";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import Chatbot from "./Chat/Chatbot";
import IntakeForm from "./Drawer/IntakeForm";

import RequestManager from "./Drawer/RequestManager";
import { fetchUser } from "../api/auth";
// import { resetConversationId } from "../api/chat";
import useFormConfig from "../hooks/useFormConfig"; // New custom hook
import useChat from "../hooks/useChat"; // New custom hook
import { logout } from "../api/auth";

import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

// Remove the fixed drawerWidth
// const drawerWidth = "60%";

const handleLogout = async () => {
  try {
    await logout();
    console.log("Logged out successfully");
    window.location.href = "/";
  } catch (error) {
    console.error("Failed to log out:", error);
  }
};

function Dashboard() {
  const navigate = useNavigate(); // Initialize useNavigate

  const {
    messages,
    setMessages,
    drawerOpen,
    setDrawerOpen,
    activePolicy,
    setActivePolicy,
    conversationOver,
    setConversationOver,
    initialOptions,
    context,
    setContext,
    chatbotLoading,
    setChatbotLoading,
  } = useChat();

  const { formEndRef, latestUpdateId, setLatestUpdateId } = useFormConfig();

  const [allFiles, setAllFiles] = useState([]);

  const [drawerLoadingCount, setDrawerLoadingCount] = useState(0);

  const handleDrawerUpdate = async (data) => {
    console.log("Data: ", data);
  };

  const [user, setUser] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const data = await fetchUser();
      setUser(data);
      return data;
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchUserData().then((user) => {
      console.log("USER: ", user);
    });
  }, [fetchUserData]);

  // Determine drawerWidth based on activePolicy
  const drawerWidth = "50%";

  // New useEffect to open the drawer when there are at least 4 messages
  useEffect(() => {
    if (activePolicy === "intake") {
      setDrawerOpen(true);
    } else if (
      activePolicy === "manage_request" &&
      context.manage_request.open_request
    ) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  }, [messages, setDrawerOpen, activePolicy]);

  useEffect(() => {}, [setDrawerOpen, activePolicy]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: 0,
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            marginRight: (theme) => (drawerOpen ? 0 : `-${drawerWidth}`),
            position: "relative",
            ...(drawerOpen && {
              transition: (theme) =>
                theme.transitions.create("margin", {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }),
          }}
        >
          <Box
            sx={{
              maxHeight: "7%",
              py: 2,
              pl: 2,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Tooltip title="Home">
              <IconButton
                color="primary"
                onClick={() => (window.location.href = "/")}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                color="error"
                onClick={handleLogout}
                variant="outlined"
                sx={{ ml: 0 }}
              >
                <LogoutIcon sx={{ width: 24, height: 24 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title=" Reset Chat">
              <IconButton
                color="success"
                onClick={() => (window.location.href = "/")}
              >
                <RotateLeftRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            sx={{
              width: drawerOpen ? "100%" : "80%",
              height: "92%",
              mx: "auto",
              mb: 2,
              px: 2,
            }}
          >
            <Chatbot
              user={user}
              formEndRef={formEndRef}
              drawerLoadingCount={drawerLoadingCount}
              setDrawerLoadingCount={setDrawerLoadingCount}
              latestUpdateId={latestUpdateId}
              setLatestUpdateId={setLatestUpdateId}
              context={context}
              setContext={setContext}
              conversationOver={conversationOver}
              setConversationOver={setConversationOver}
              allFiles={allFiles}
              setAllFiles={setAllFiles}
              messages={messages}
              setMessages={setMessages}
              setOpen={setDrawerOpen}
              handleDrawerUpdate={handleDrawerUpdate}
              activePolicy={activePolicy}
              setActivePolicy={setActivePolicy}
              initialOptions={initialOptions}
              chatbotLoading={chatbotLoading}
              setChatbotLoading={setChatbotLoading}
              handleLogout={handleLogout}
            />
          </Box>
        </Box>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={drawerOpen}
        >
          <Divider />
          {activePolicy === "intake" && context.intake && (
            <IntakeForm
              context={context}
              setContext={setContext}
              conversationOver={conversationOver}
              setConversationOver={setConversationOver}
              allFiles={allFiles}
              setAllFiles={setAllFiles}
              chatbotLoading={chatbotLoading}
            />
          )}
          {activePolicy === "manage_request" &&
            context.manage_request.open_request && (
              <RequestManager
                context={context}
                setContext={setContext}
                conversationOver={conversationOver}
                setConversationOver={setConversationOver}
                allFiles={allFiles}
                setAllFiles={setAllFiles}
                chatbotLoading={chatbotLoading}
              />
            )}
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
